@import '../globals';


.result {
  display: flex;
}

.value {
  font-size: 1.8em;
  color: $color-primary;
  margin-right: $margin-lg*2;
  margin-bottom: $margin-lg !important;
}
