@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Raleway&display=swap');
@import 'globals';

body {
  margin: $margin-md;
  line-height: 1.8em;
  font-size: 17px;
}

select {margin-bottom: $margin-lg !important}

h1 {font-family: 'Cormorant Garamond', serif;}

.app {
  margin: 0 auto;
  font-family: 'Cormorant Garamond', serif;
  max-width: 350px;
}
h1 a {
  text-decoration: none;
}

header {
  display: flex;
  justify-content: space-between;


  ul {
    display: flex;
    align-items: center;
  }
  li {margin-right: $margin-md;}
}